import { CloverApi } from '@/api-client/clover';
import CreditCardInput from '@/app/(client)/booking/_component/CreditCard2';
import ZipInput from '@/app/(client)/booking/_component/ZipInput';
import { countryOptions } from '@/data/country';
import { useAuth } from '@/hooks/auth.hook';
import { removeAscent } from '@/utils/convert';
import { handleDateInputExprise } from '@/utils/global-func';
import { Button, DatePicker, DatePickerProps, Form, Input, Modal, notification, Select } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

interface IProfileAddCardAction {
  membershipCategoryId: string;
  open: boolean;
  changeOpen: (open: boolean) => void;
  reload: () => void;
  card: any;
  reference?: string;
}

export const ProfileAddCardAction: FC<IProfileAddCardAction> = props => {
  const { open, changeOpen, membershipCategoryId, reload, card, reference } = props;

  const { mutateProfile } = useAuth();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState<any>(() => dayjs(new Date()));
  const [cardInfo, setCardInfo] = useState({ number: '', type: '' });

  const handleRemoveCard = async () => {
    if (loading || !reference) return;

    setLoading(true);

    try {
      await CloverApi.deleteCardByReference(reference);
      reload();
    } catch (error: any) {
      notification.error({
        message: 'Error deleting data',
        description: error?.response ? error.response.data?.message : error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChangeFormCard = (e: any, name: string) => {
    const { value } = e.target;
    const noAccentValue = removeAscent(value);
    form.setFieldsValue({ [name]: noAccentValue });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setDate(date);
    // setDateStr(dateString.toString());
  };

  const handleCardChange = (info: any) => {
    setCardInfo(info);
  };

  return (
    <Modal
      maskClosable={false} // not click mask
      loading={loading}
      width={480}
      open={open}
      footer={[]}
      onClose={() => changeOpen(false)}
      onCancel={() => changeOpen(false)}
      centered
      title={<span className='font-medium text-zelene-blue'>{card ? 'Replace' : 'Create'} Payment Card</span>}
    >
      <div className='pt-4'>
        <Form
          form={form}
          layout='vertical'
          initialValues={{ country: 'US' }}
          onFinish={async vals => {
            if (loading) return;

            const rs = new Date(date);
            const month = (rs.getMonth() + 1).toString().padStart(2, '0');
            const year = rs.getFullYear().toString().slice(-2);

            const cardData = {
              name: vals.name,
              address_line1: vals.address,
              address_line2: null,
              address_city: vals.city,
              address_state: vals.state,
              address_zip: vals.code,
              address_country: vals.country,
              number: vals.card_number,
              exp_month: month,
              exp_year: year,
              cvv: vals.CVV,
              brand: cardInfo.type,
            };

            if (card) {
              await handleRemoveCard();
            }

            setLoading(true);
            try {
              await CloverApi.savePaymentInfoByCategory(membershipCategoryId, cardData);
              mutateProfile();
              reload();
              form.resetFields();
              changeOpen(false);
            } catch (err: any) {
              notification.error({
                message: 'Create Card Failed',
                description: `${err?.response?.data?.message}`,
              });
            } finally {
              setLoading(false);
            }
          }}
        >
          <div className='flex flex-col'>
            <span className='pb-4 font-hneu text-[20px] font-medium leading-normal text-zelene-blue'>
              {'Billing Address'}
            </span>

            <FormItem
              label='Address'
              name={'address'}
              rules={[{ required: true, message: 'Please input your address!' }]}
            >
              <Input placeholder='Address' onChange={e => handleInputChangeFormCard(e, 'address')} />
            </FormItem>

            <div className='flex flex-col gap-4 *:flex-1 sm:flex-row'>
              <FormItem
                label='Country'
                name={'country'}
                rules={[{ required: true, message: 'Please input your country!' }]}
              >
                <Select
                  showSearch
                  options={countryOptions}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  placeholder='Country'
                />
              </FormItem>

              <FormItem label='City' name={'city'} rules={[{ required: true, message: 'Please input your city!' }]}>
                <Input placeholder='City' onChange={e => handleInputChangeFormCard(e, 'city')} />
              </FormItem>
            </div>

            <div className='flex flex-col gap-4 *:flex-1 sm:flex-row'>
              <FormItem label='State' name={'state'} rules={[{ required: true, message: 'Please input your state!' }]}>
                <Input placeholder='State' onChange={e => handleInputChangeFormCard(e, 'state')} />
              </FormItem>

              <ZipInput />
            </div>

            <span className='pb-4 font-hneu text-[20px] font-medium leading-normal text-zelene-blue'>
              {'Card Infomation'}
            </span>

            <FormItem label='Name' name={'name'} rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input placeholder='Name' onChange={e => handleInputChangeFormCard(e, 'name')} />
            </FormItem>

            <CreditCardInput onCardChange={handleCardChange} />

            <div className='flex flex-col gap-4 *:flex-1 sm:flex-row'>
              <FormItem
                label='Expiration Date'
                name={'card_date'}
                rules={[{ required: true, message: 'Please fill out your card expiration date.' }]}
              >
                <DatePicker
                  placeholder='MM/YY'
                  value={date}
                  mode='month'
                  picker='month'
                  className='w-full'
                  format={'MM/YY'}
                  onChange={onChange}
                  onKeyDown={handleDateInputExprise}
                />
              </FormItem>

              <FormItem
                label='Security Code'
                name={'CVV'}
                rules={[
                  { required: true, message: 'Please input your cvv!' },
                  { pattern: /^[0-9]{3,4}$/, message: 'CVV must be 3 or 4 digits' },
                ]}
              >
                <Input placeholder='CVV' />
              </FormItem>
            </div>

            <Button
              size='large'
              className='relative bottom-0 flex w-fit flex-1 !self-end !rounded-[25px] !bg-zelene-blue !px-6 !py-3 pt-10 !text-white'
              onClick={() => {
                form.submit();
              }}
              type='primary'
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
