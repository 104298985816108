import { FC, useState } from 'react';
import { CgArrowsExchange } from 'react-icons/cg';
import { FaCaretUp } from 'react-icons/fa';
import { GoPlus } from 'react-icons/go';
import { ProfileAddCardAction } from './add-card';

interface ICardControlBox {
  card: any;
  reference?: string;
  reload: () => void;
  membershipCategoryId: string;
}

export const CardControlBox: FC<ICardControlBox> = props => {
  const { card, reference, reload, membershipCategoryId } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [openCreateCard, setOpenCreateCard] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div
      className='flex flex-col p-4'
      style={{
        borderRadius: '8px',
        background: '#fff',
        boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.10)',
        padding: '16px 20px',
      }}
    >
      <div
        className='mb-4 flex cursor-pointer flex-row items-center justify-between'
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className='font-hneu'
          style={{
            color: '#1D3F75',
            fontSize: '12px',
          }}
        >
          Payment Method
        </span>
        <FaCaretUp style={{ rotate: isOpen ? '180deg' : '0deg', transition: 'all ease .3s' }} />
      </div>

      <div
        className={`flex flex-col gap-2 overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-40' : 'max-h-0'}`}
      >
        {card ? (
          <div
            className='flex flex-row items-center justify-center gap-[10px] px-4 py-2'
            style={{
              borderRadius: '11px',
              border: '1px solid #D29A2A',
            }}
          >
            <span
              className='font-hneu'
              style={{
                color: '#1D3F75',
                fontSize: '12px',
              }}
            >
              {card?.cardType}
            </span>

            <div className='flex flex-1 flex-row items-center justify-between'>
              <span
                className='font-hneu'
                style={{
                  color: '#1D3F75',
                  fontSize: '12px',
                }}
              >
                **** **** **** {card.last4}
              </span>
              <span
                className='font-hneu'
                style={{
                  color: '#1D3F75',
                  fontSize: '12px',
                }}
              >
                {card.expirationDate.slice(0, 2) + '/' + card.expirationDate.slice(2)}
              </span>
            </div>

            {/* <IoRemoveCircleSharp
              className='cursor-pointer text-xl text-red-500 hover:text-red-600'
              style={{ transition: 'all ease .3s' }}
              onClick={async () => {
                if (loading || !reference) return;

                setLoading(true);

                try {
                  await CloverApi.deleteCardByReference(reference);
                  reload();
                } catch (error: any) {
                  notification.error({
                    message: 'Error deleting data',
                    description: error?.response ? error.response.data?.message : error?.message,
                  });
                } finally {
                  setLoading(false);
                }
              }}
            /> */}
          </div>
        ) : (
          <></>
        )}

        <div
          className='flex cursor-pointer flex-row items-center justify-center gap-[10px]'
          style={{ border: '0.5px dashed #1D3F75', padding: '8px 32px', borderRadius: '11px' }}
          onClick={() => setOpenCreateCard(true)}
        >
          {card ? (
            <CgArrowsExchange className='text-2xl text-[#1D3F75]' />
          ) : (
            <GoPlus className='text-2xl text-[#1D3F75]' />
          )}
          <span className='font-hneu' style={{ color: '#1D3F75', fontSize: '14px', lineHeight: '17px' }}>
            {card ? 'Replace' : 'Add'} Payment Method
          </span>
        </div>
      </div>

      <ProfileAddCardAction
        membershipCategoryId={membershipCategoryId}
        open={openCreateCard}
        changeOpen={function (open: boolean): void {
          setOpenCreateCard(open);
        }}
        reload={reload}
        card={card}
        reference={reference}
      />
    </div>
  );
};
