import { membershipCategoryApi } from '@/api-client/membership-category';
import Loading from '@/components/elements/Loading';
import { useAuth } from '@/hooks/auth.hook';
import useMobileScreen from '@/hooks/useMobileScreen';
import { IMembershipCategories } from '@/interface/membership';
import { capitalizeFirstLetter } from '@/utils/global-func';
import { Divider, Select, Tabs } from 'antd';
import clsx from 'clsx';
import Image from 'next/image';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Membership from '../../../../../../public/svgs/Membership.svg';
import MembershipDetailItem from './item';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    &::before {
      border: none !important; /* Remove bottom border */
    }
  }

  .ant-tabs-content-holder {
    padding-top: 5px !important;
  }

  .ant-tabs-nav-list {
    // background-color: #f0f2f5;
    border-radius: 6px;
    padding: 4px;
    gap: 4px;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 16px;
    border-radius: 6px;
    color: #637381;
    font-weight: 500;
    transition: all 0.3s;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:hover {
      color: #1d3f75;
    }

    &.ant-tabs-tab-active {
      background-color: #1d3f75;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

interface ICustomerMembershipDetail {
  location: string | null;

  storeBranchesChoose: string | null;
  storeBranches: any[];
  setStoreBranchesChoose: (value: string | null) => void;
}

export const CustomerMembershipDetail: FC<ICustomerMembershipDetail> = props => {
  const { location, storeBranchesChoose, storeBranches, setStoreBranchesChoose } = props;
  const { profile } = useAuth();

  const { memberships } = profile;

  const currentMembershipCard = memberships?.find(m => {
    const { policy } = m;

    if (!location) {
      return false;
    }

    return policy?.category?.branchesApply?.map(b => b._id).includes(location);
  });

  const [loading, setLoading] = useState(false);
  const [MembershipCategories, setMembershipCategories] = useState<IMembershipCategories[]>([]);
  const isMobile = useMobileScreen();
  const [categoryActive, setCategoryActive] = useState<any>(null);

  const detailRef: any = useRef(null);

  const handleScrollToDetail = (category: any) => {
    setCategoryActive(category);
    if (detailRef.current) {
      detailRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const fetchMembershipCategories = useCallback(async () => {
    setLoading(true);
    const { data } = await membershipCategoryApi.paginate({ active: 'true' });

    if (!location) {
      setMembershipCategories(
        data.categories.map((category: any) => ({
          ...category,
          branchesApply: category.branchesApply.map((branch: any) => ({
            ...branch,
            time_works: branch.time_works || [], // Ensure time_works exists
          })),
        })),
      );
      return;
    }

    const categories = data.categories.filter((v: any) => {
      const ids: string[] = v.branchesApply.map((i: any) => i._id);
      return ids.includes(location);
    });

    setMembershipCategories(
      categories.map((category: any) => ({
        ...category,
        branchesApply: category.branchesApply.map((branch: any) => ({
          ...branch,
          time_works: branch.time_works || [], // Ensure time_works exists
        })),
      })),
    );
    setCategoryActive(categories[0]);
    setLoading(false);
  }, [location]);

  useEffect(() => {
    fetchMembershipCategories();
  }, [fetchMembershipCategories]);

  if (loading) {
    return (
      <div className='flex h-[650px] items-center justify-start'>
        <Loading />
      </div>
    );
  }

  if (MembershipCategories.length === 0) {
    return (
      <div className='flex h-[650px] items-center justify-center'>
        <span className='font-hneu text-lg font-semibold'>The membership configuration in this branch is empty.</span>
      </div>
    );
  }

  const activeMembershipCat = currentMembershipCard?.policy?.category;
  if (isMobile) {
    return (
      <div className='flex flex-col gap-2'>
        <div className='mt-2 flex flex-col gap-4'>
          {activeMembershipCat ? (
            <div className='mb-2 font-hneu font-semibold text-zelene-dark-blue'>
              Your current membership is <span className='text-zelene-red'> {activeMembershipCat.name}</span>. Enjoy the
              benefits!
            </div>
          ) : null}

          <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Categories</div>
          {MembershipCategories.map(category => {
            const activeMembership = currentMembershipCard?.policy?.category?._id === category?._id;
            const activeTab = categoryActive?._id === category?._id;

            return (
              <div
                onClick={() => {
                  handleScrollToDetail(category);
                }}
                className={clsx(
                  'cursor-pointer rounded-[11px] border !border-zelene-grey p-3 font-medium text-zelene-dark-blue shadow-md',
                  activeTab && 'bg-zelene-blue !text-white',
                )}
                key={category._id}
              >
                {activeMembership ? (
                  <div className='flex items-center gap-2'>
                    <Image src={Membership} alt='icon' width={16} height={16} className='size-4 object-contain' />
                    {capitalizeFirstLetter(category.sort_name)}
                  </div>
                ) : (
                  capitalizeFirstLetter(category.sort_name)
                )}
              </div>
            );
          })}
        </div>

        <Divider className='!my-0 !mt-2' />

        <div className='flex flex-col gap-2'>
          <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Location</div>
          <Select
            size='large'
            showSearch
            className='w-full'
            placeholder=''
            optionFilterProp='label'
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            value={storeBranchesChoose}
            options={storeBranches.map((v: any) => ({
              label: v.name,
              value: v._id,
            }))}
            onChange={value => {
              setStoreBranchesChoose(value);
            }}
          />
        </div>

        <div ref={detailRef} id='detail-category mt-3'>
          <MembershipDetailItem
            setStoreBranchesChoose={setStoreBranchesChoose}
            storeBranches={storeBranches}
            storeBranchesChoose={storeBranchesChoose}
            item={categoryActive}
            membershipCard={currentMembershipCard}
            location_target={location}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='mt-2 flex flex-col gap-4'>
        {activeMembershipCat ? (
          <div className='mb-4 font-hneu font-semibold text-zelene-dark-blue'>
            Your current membership is <span className='text-zelene-red'>{activeMembershipCat.name}</span>. Enjoy the
            benefits!
          </div>
        ) : null}
      </div>
      <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Categories</div>
      <StyledTabs
        className='w-full'
        centered
        defaultActiveKey='1'
        items={MembershipCategories.map(category => {
          const activeMembership = currentMembershipCard?.policy?.category?._id === category?._id;
          return {
            key: category._id,
            label: (
              <div className='flex items-center justify-center gap-2'>
                {activeMembership && (
                  <Image src={Membership} alt='icon' width={16} height={16} className='size-4 object-contain' />
                )}
                {capitalizeFirstLetter(category.sort_name)}
              </div>
            ),
            children: (
              <MembershipDetailItem
                setStoreBranchesChoose={setStoreBranchesChoose}
                storeBranches={storeBranches}
                storeBranchesChoose={storeBranchesChoose}
                item={category}
                membershipCard={currentMembershipCard}
                location_target={location}
              />
            ),
          };
        })}
      />
    </>
  );
};
