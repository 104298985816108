import { useMembershipDetailContext } from '@/components/contexts/membership-detail.context';
import ContentMembershipForm from '@/components/custom/membership-form/content';
import { IMembershipCategories } from '@/interface/membership';
import { convertMonth } from '@/utils/convert';
import { Button, Divider, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';

export interface IConsentFormCustomerProps {
  membershipCategory: IMembershipCategories | null;
  handleSubmit: any;
}

export default function ConsentFormCustomer(props: IConsentFormCustomerProps) {
  const { handleSubmit, membershipCategory } = props;
  const viewRef = useRef<any>();
  const addressRef = useRef(null);
  const dateTimeRef = useRef(null);
  const containerRef = useRef<any>();
  const [width, setWidth] = useState(0);

  const {
    setFormData,
    setErrorSign,
    dateTime,
    setErrorName,
    setName,
    name,
    setErrorAddress,
    setAddress,
    address,
    errorName,
    errorAddress,
    errorSign,
    loadingForm,
    sigCanvas,
    clear,
  } = useMembershipDetailContext();

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    updateWidth(); // Initial width set
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const save = () => {
    if (sigCanvas.current) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      //   setImageURL(signature);
      setErrorSign('');
      base64ToFile(signature);
    }
  };

  function base64ToFile(base64: string) {
    const byteString = atob(base64?.split(',')[1]); // Decode base64 string to binary data
    const mimeType = base64?.split(',')[0].split(':')[1].split(';')[0]; // Extract the MIME type

    // Convert binary data to an array of 8-bit unsigned integers
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: mimeType });
    // Optionally convert Blob to File (if you need a File object)
    const rs = new File([blob], 'image.png', { type: mimeType });
    setFormData(rs);
    return rs;
  }

  return (
    <div className='relative'>
      <span
        ref={viewRef}
        className='flex justify-center pb-8 text-center font-hneu text-[18px] font-semibold uppercase'
      >
        Zelene Head Spa
      </span>

      <div className='flex max-h-[75vh] flex-col gap-4 overflow-auto pb-5 font-hneu text-sm text-zelene-blue'>
        <p className='mb-0 font-hneu font-normal' ref={dateTimeRef}>
          <span className='font-semibold'>THIS GENERAL SERVICE AGREEMENT (the "Agreement") is dated this</span>{' '}
          <Input
            size='small'
            style={{ width: 50, borderBottom: '1px solid #808080' }}
            className='!text-center !text-black'
            variant='borderless'
            disabled
            value={dateTime.toDate().getDate()}
          />{' '}
          <span className='font-semibold'>day of</span>{' '}
          <Input
            size='small'
            style={{ width: 120, borderBottom: '1px solid #808080' }}
            className='!text-center !text-black'
            variant='borderless'
            disabled
            value={convertMonth(dateTime?.toDate().getMonth() + 1)}
          />
          <span className='font-semibold'>, </span>
          <Input
            size='small'
            style={{ width: 100, borderBottom: '1px solid #808080' }}
            className='!text-center !text-black'
            variant='borderless'
            disabled
            value={dateTime.toDate().getFullYear()}
          />
          <span className='font-semibold'>.</span>
        </p>

        <div className='flex flex-col gap-2'>
          <div className='w-full rounded-lg border border-zelene-blue p-4'>
            <div className='flex w-full justify-center'>
              <p className='mb-0 text-sm font-bold'>MEMBER</p>
            </div>
            <div className='flex h-full w-full flex-col items-center justify-center gap-1'>
              <p className='mb-0 text-sm font-medium'>
                Client Name:{' '}
                <Input
                  size='middle'
                  // borderBottom: '1px solid #808080'
                  style={{ width: 230, fontSize: 14 }}
                  // variant='borderless'
                  placeholder='Client name...'
                  value={name}
                  className='placeholder:!text-sm'
                  onChange={e => {
                    setName(e.target.value);
                    setErrorName('');
                  }}
                />
              </p>
              {errorName && <p className='mb-0 font-hneu text-sm font-normal italic text-red-400'>*{errorName}</p>}
              <p className='mb-0 text-sm font-medium' ref={addressRef}>
                Address:{' '}
                <Input
                  size='middle'
                  style={{ width: 260, fontSize: 14 }}
                  // variant='borderless'
                  placeholder='Address...'
                  value={address}
                  onChange={e => {
                    setAddress(e.target.value);
                    setErrorAddress('');
                  }}
                />
              </p>
              {errorAddress && (
                <p className='mb-0 font-hneu text-sm font-normal italic text-red-400'>*{errorAddress}</p>
              )}
            </div>
          </div>
          <div className='w-full rounded-lg border border-zelene-blue p-4'>
            <div className='flex w-full flex-col items-center gap-4'>
              <p className='mb-0 text-sm font-bold'>BUSINESS</p>
              <div>
                <p className='mb-0 text-center text-sm font-medium'>
                  Zelene{' '}
                  {membershipCategory?.branchesApply && membershipCategory?.branchesApply?.length > 0
                    ? membershipCategory?.branchesApply?.map((branch, index) =>
                        index === 0 ? branch?.name : ', ' + branch?.name,
                      )
                    : ''}{' '}
                  Location
                </p>
              </div>
            </div>
          </div>
        </div>

        <ContentMembershipForm category={membershipCategory || null} />
        <Divider className='!my-2 bg-gray-300' />
        <div>
          <span className='!mb-2 font-hneu text-base font-medium text-zelene-blue'>Signature:</span>
          <div className='flex w-full gap-2 overflow-hidden rounded-lg border bg-white shadow-lg sm:w-full'>
            <div ref={containerRef} style={{ width: '100%' }}>
              <ReactSignatureCanvas
                ref={sigCanvas}
                penColor='black'
                onEnd={save} // Khi ký xong thì lưu lại chữ ký
                canvasProps={{ width: width, height: 160, className: 'sigCanvas' }}
              />
            </div>
          </div>
          {errorSign && <p className='mb-0 font-hneu text-sm font-normal italic text-red-400'>*{errorSign}</p>}
        </div>
        <div className='flex flex-row justify-between gap-3'>
          <p className='mb-0 text-sm font-normal'>
            CLIENT NAME:{' '}
            <Input
              size='middle'
              style={{ width: 220, fontSize: 14 }}
              placeholder='Client name...'
              value={name?.toUpperCase()}
              onChange={e => {
                setName(e.target.value);
                setErrorName('');
              }}
            />
          </p>
          <div className='flex w-full justify-end lg:w-fit'>
            <Button type='dashed' danger onClick={clear} className='flex !self-end text-end'>
              Clear sign
            </Button>
          </div>
        </div>
        {errorName && <p className='mb-0 font-hneu text-sm font-normal italic text-red-400'>*{errorName}</p>}

        <div className='flex justify-center pt-5'>
          <Button
            onClick={handleSubmit}
            className='!rounded-full !bg-zelene-red !px-8'
            size='large'
            type='primary'
            htmlType='submit'
            loading={loadingForm}
            disabled={loadingForm}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}
