import axiosClient from '@/helper/call-center';
import { MembershipCategoryApplyLocation } from './interface/list-category-apply-location';

export const MembershipApi = {
  // Get method
  getAll() {
    return axiosClient.get(`/membership`);
  },
  findById(id: string) {
    return axiosClient.get(`/membership/by-id/${id}`);
  },
  findCategoryById(id: string) {
    return axiosClient.get(`/membership-category/by-id/${id}`);
  },
  getMembershipInfoByEmployee(customerId: string) {
    return axiosClient.get(`/membership/customer-membership-by-employee/${customerId}`);
  },
  getMembershipByLocationAndId: (customerId: string, branchId: string) =>
    axiosClient.get(`/membership/by-id-customer/${customerId}/apply-branch/${branchId}`),
  getDetailByLocation(location: string) {
    return axiosClient.get(`/membership/by-location/${location}`);
  },
  calculateDifference(params: { membershipId: string; targetCategoryId: string }) {
    const { membershipId, targetCategoryId } = params;
    return axiosClient.get(`/membership/calculate-difference/${membershipId}/${targetCategoryId}`);
  },
  getMembershipInfoByEmployeeRefBranch(customerId: string, branch: string) {
    return axiosClient.get(`/membership/customer-membership-by-employee/${customerId}/branch/${branch}`);
  },
  getListMembershipByLocation(location: string) {
    return axiosClient.get<MembershipCategoryApplyLocation[]>(`/membership-category/apply-to-location/${location}`);
  },
  regularByLocation(params: { location: string; search?: string }) {
    const { location, ...rest } = params;
    return axiosClient.get(`/membership/regular/${location}`, { params: rest });
  },
  checkRequestCreateMembership(id: string) {
    return axiosClient.get(`/membership/check-request-register-card/${id}`);
  },
  checkRequestCreateMembershipByStaff(customer: string, location: string) {
    return axiosClient.get(`/membership/check-request-register-card-by-staff/${customer}/${location}`);
  },

  // Post method
  customerRegister(params: FormData) {
    return axiosClient.post('/membership/register-card', params, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  createCardByEmployee(
    customerId: string,
    params: {
      is_recurring_enabled?: boolean;
      categoryId: string;
      location_target: string;
    },
    isUpgrade?: boolean,
  ) {
    if (isUpgrade) return axiosClient.post(`/membership/upgrade-card/${customerId}`, params);
    else return axiosClient.post(`/membership/register-card-by-employee/${customerId}`, params);
  },
  request(id: string, storeBranchId: string) {
    return axiosClient.post(`/membership/request-consent-form/${id}/${storeBranchId}`);
  },
  // createCardCategory(params: IMembershipCategory) {
  //   return axiosClient.post('/membership/register-card', params);
  // },

  // Put/Patch method
  updateCardCategory(id: string) {
    return axiosClient.put(`/membership-category/update-image/${id}`);
  },
  updateAutoRenewalCard(membershipCardId: string, status: boolean) {
    return axiosClient.patch(`/membership/change-enable-auto-renew/${membershipCardId}`, {
      is_recurring_enabled: status,
    });
  },
  updateFormImage(id: string, formData: FormData) {
    return axiosClient.put(`/membership/update-form-image/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  changeServiceFreeTarget(params: { id: string; serviceId: string }) {
    const { id, serviceId } = params;
    return axiosClient.patch(`/membership-category/by-id/${id}/change-service-free-target/${serviceId}`);
  },
  changeAddonFreeTarget(params: { id: string; addonId: string }) {
    const { id, addonId } = params;
    return axiosClient.patch(`/membership-category/by-id/${id}/change-addon-free-target/${addonId}`);
  },
  earlyCancel(membershipId: string, cancel_at?: string) {
    if (cancel_at) {
      return axiosClient.patch(`/membership/make-early-cancel/${membershipId}`, { cancel_at });
    } else {
      return axiosClient.patch(`/membership/make-early-cancel/${membershipId}`);
    }
  },
  deleteFormImgByUrl(id: string, url: string) {
    return axiosClient.put(`/membership/delete-form-image/${id}`, { url });
  },

  // Delete method
  deleteCard(params: { membershipId: string }) {
    return axiosClient.delete(`/membership/cancel/${params.membershipId}`);
  },
  deleteCardByEmployee(params: { membershipId: string }) {
    return axiosClient.delete(`/membership/cancel-by-employee/${params.membershipId}`);
  },
};

interface IMembershipCategory {
  name: string;
  description: string;
  day_counts: number;
  price: number;
  freeServicesIds: [{ count: number; target: string }];
  freeAddonsIds: [{ count: number; target: string }];
  storeBranchId: string;
  discountAddon: string;
  discountServices: string;
  discountProducts: string;
  pointMultiplier: number;
}
