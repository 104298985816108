// format price $580.00 USD

export const formatPrice = (price?: number | string) => {
  if (price === undefined || price === null) {
    return '';
  }
  //   if string, convert to number
  const priceNum = typeof price === 'string' ? parseFloat(price) : price;
  return priceNum.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' USD';
};
// format price if .00 then remove
export const formatPriceV2 = (price?: number | string) => {
  if (!price) {
    return '';
  }
  //   if string, convert to number
  const priceNum = typeof price === 'string' ? parseFloat(price) : price;
  return priceNum.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).replace('.00', '') + ' USD';
};

// hh:mm MM/DD/YYYY
export const formatDate = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${hours}:${minutes} ${month}/${day}/${year}`;
};
