import useMobileScreen from '@/hooks/useMobileScreen';
import { useMembershipAction } from '@/store/membershipOpen';
import { Modal } from 'antd';
import { MembershipPersonal } from '../membership-personal';

interface IProfileMembershipProps {
  open: boolean;
  close: () => void;
}

const ProfileMembership = (props: IProfileMembershipProps) => {
  const { open, close } = props;
  const isMobile = useMobileScreen();
  const { membershipOpen, close: CloseStore } = useMembershipAction();

  return (
    <Modal
      width={isMobile ? '99%' : '55%'}
      title={
        <div className='flex w-full items-start justify-start border-b border-slate-200'>
          <p className='mb-0 text-xl font-bold text-zelene-blue'>Your Membership</p>
        </div>
      }
      open={open || membershipOpen}
      onClose={() => {
        CloseStore();
        window.location.reload();
      }}
      onCancel={() => {
        CloseStore();
        window.location.reload();
      }}
      footer={[]}
      centered
    >
      <div className='!max-h-[80vh] overflow-y-auto'>
        <MembershipPersonal />
      </div>
    </Modal>
  );
};

export default ProfileMembership;
