import { Drawer } from 'antd';
import ConsentFormCustomer from './consent-form';

export interface ICreateMembershipFormCustomerProps {
  open: boolean;
  onClose: any;
  membershipCategory: any;
  handleSubmit: any;
}

export default function CreateMembershipFormCustomer(props: ICreateMembershipFormCustomerProps) {
  const { open, onClose, membershipCategory, handleSubmit } = props;
  return (
    <Drawer
      //   loading={loading}
      title=''
      open={open}
      onClose={() => {
        onClose();
      }}
      width={500}
      placement='right'
      styles={{ body: { overflow: 'hidden' } }}
    >
      <div className='flex w-full justify-center'>
        <div className=''>
          <p className='mb-0 px-2 py-5 text-center font-prettywise text-3xl font-semibold sm:px-0'>
            MEMBERSHIP SERVICE AGREEMENT
          </p>
          <div className='flex w-full justify-center overflow-auto'>
            <div>
              <ConsentFormCustomer membershipCategory={membershipCategory || null} handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
