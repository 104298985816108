import dayjs from 'dayjs';
import { createContext, useContext, useMemo, useRef, useState } from 'react';

//  Context
export type MembershipDetailContextType = {
  formData: any;
  setFormData: (data: any) => void;
  dateTime: any;
  name: string;
  setName: (name: string) => void;
  address: string;
  setAddress: (address: string) => void;
  errorName: string;
  setErrorName: (error: string) => void;
  errorAddress: string;
  setErrorAddress: (error: string) => void;
  errorSign: string;
  setErrorSign: (error: string) => void;
  loadingForm: boolean;
  setLoadingForm: (loading: boolean) => void;
  clearForm: () => void;
  sigCanvas: any;
  clear: () => void;
};
export const MembershipDetailContext = createContext({} as MembershipDetailContextType);

type TProps = {
  children: React.ReactNode;
};

export const MembershipDetailProvider = ({ children }: TProps) => {
  //* init state
  const sigCanvas = useRef({}) as any;

  const [formData, setFormData] = useState<any>();
  const dateTime = dayjs();
  const [name, setName] = useState<string>('');
  const [address, setAddress] = useState<string>('');

  // error field
  const [errorName, setErrorName] = useState('');
  const [errorAddress, setErrorAddress] = useState('');
  const [errorSign, setErrorSign] = useState('');

  const [loadingForm, setLoadingForm] = useState(false);

  const clear = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
    // setImageURL(null);
    setFormData(null);
  };

  const clearForm = () => {
    setName('');
    setAddress('');
    setErrorName('');
    setErrorAddress('');
    setErrorSign('');
    clear();
  };

  const value = useMemo(
    () => ({
      formData,
      setFormData,
      dateTime,
      name,
      setName,
      address,
      setAddress,
      errorName,
      setErrorName,
      errorAddress,
      setErrorAddress,
      errorSign,
      setErrorSign,
      loadingForm,
      setLoadingForm,
      clearForm,
      sigCanvas,
      clear,
    }),
    [formData, dateTime, name, address, errorName, errorAddress, errorSign, loadingForm, sigCanvas],
  );

  return <MembershipDetailContext.Provider value={value}>{children}</MembershipDetailContext.Provider>;
};

//  Context hooks
export const useMembershipDetailContext = () => useContext(MembershipDetailContext);
