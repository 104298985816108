import clsx from 'clsx';
import Link from 'next/link';
import { FaRegUserCircle } from 'react-icons/fa';
// import CartICon from '/svgs/cart-icon.svg';
import Image from 'next/image';

interface AuthBtnProps {
  color?: string;
}

export const AuthBtn = (props: AuthBtnProps) => {
  const { color } = props;
  return (
    <Link href='/login' className='z-10 flex w-fit cursor-pointer flex-row items-center gap-2' title='Login'>
      <div className='relative flex size-9 items-center justify-center'>
        {/* <FaRegUserCircle className={clsx('!size-7 font-normal', color ? color : '!text-[#1D3F75]')} /> */}
        <Image
                  src='/svgs/profile-icon.svg'
                  alt='profile icon'
                  width={24}
                  height={24}
                  className='m-6 size-[1.6rem] object-contain'
                />
      </div>
      {/* <div className='relative size-9'>
        <Image
          src='/images/user-profile.png'
          alt='profile icon'
          sizes='24px'
          fill
          className='mx-[6px] size-6 object-contain'
        />
      </div> */}
    </Link>
  );
};
