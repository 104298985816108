import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export interface IConfirmModalProps {
  title: string;
  open: boolean;
  handleConfirm: () => void;
  content: string;
  handleCancel: () => void;
  isLoading?: boolean;
  hiddenCancel?: boolean;
  hiddenOk?: boolean;
}

export default function ConfirmModal(props: IConfirmModalProps) {
  const { title, open, handleConfirm, content, handleCancel, isLoading, hiddenCancel, hiddenOk } = props;

  return (
    <Modal
      title={
        <div className='flex w-full justify-start'>
          <ExclamationCircleOutlined style={{ fontSize: 20, color: '#FABC3F' }} />
          <p className='mb-0 ml-2 text-lg font-bold'>{title}</p>
        </div>
      }
      open={open}
      footer={[
        !hiddenCancel && (
          <Button key='back' onClick={handleCancel} type='default'>
            {!hiddenOk ? 'Cancel' : 'Close'}
          </Button>
        ),
        !hiddenOk && (
          <Button key='submit' type='primary' onClick={handleConfirm} loading={isLoading}>
            Accept
          </Button>
        ),
      ]}
      okText='Accept'
      cancelText='Cancel'
      closeIcon={null}
      centered
      loading={isLoading}
    >
      <p className='mb-0 text-sm font-normal'>{content}</p>
    </Modal>
  );
}
