import { IFreeTarget } from '@/interface/membership';
import { CheckCircleFilled } from '@ant-design/icons';
import { Divider } from 'antd';
import { Children, FC } from 'react';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';

interface IClientPolicyShowcase {
  policy: any;
}

export const ClientPolicyShowcase: FC<IClientPolicyShowcase> = props => {
  const { policy } = props;

  const freeTarget = policy.freeTarget as IFreeTarget[];

  const memberBenefits: { label: string; active: boolean }[] = [
    {
      label: `Earn ${policy.pointMultiplier}x Reward Points`,
      active: Number(policy.pointMultiplier) > 0,
    },
    {
      label: `${Number(policy.service_discount)}% OFF on Services and Products`,
      active: Number(policy.service_discount) > 0,
    },
  ];

  const dynamicMemberBenefits: { label: string; active: boolean }[] = (policy.benefits ?? []).map((benefit: any) => {
    let active = false;

    if (benefit.type === 'boolean') {
      active = benefit.value === true;
    } else if (benefit.type === 'number') {
      active = Number(benefit.value) > 0;
    } else if (benefit.type === 'string') {
      active = benefit.value && benefit.value.trim().length > 0;
    }

    return {
      label: benefit.label,
      active,
    };
  });

  const _freeTarget = freeTarget
    ?.map(v => {
      const freeAddonLabel = v.addons.map(addon => {
        return {
          label: `Enjoy ${addon.name} for free ${v.usage.value} times per ${v.usage.type.toLowerCase()}`,
          active: true,
        };
      });

      const freeServiceLabel = v.services.map(service => {
        return {
          label: `Enjoy ${service.name} for free ${v.usage.value} times per ${v.usage.type.toLowerCase()}`,
          active: true,
        };
      });

      return [...freeAddonLabel, ...freeServiceLabel].flat();
    })
    .flat();

  const allBenefits = [...memberBenefits, ...dynamicMemberBenefits, ...(_freeTarget ? _freeTarget : [])];

  return (
    <>
      <Divider className='!my-2' />
      <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Benefits</div>
      <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
        <div className='grid grid-cols-2 gap-[10px] *:col-span-1 *:text-[12px] *:text-zelene-slight-blue'>
          {Children.toArray(
            allBenefits.map((item: { label: string; active: boolean; value?: string }, index: number) => (
              <div key={index} className='flex flex-row items-center gap-2'>
                {item.active ? <CheckCircleFilled className='!text-green-700' /> : <MdOutlineRadioButtonUnchecked />}

                <div className='flex flex-row gap-2'>
                  <span>{item.label}</span>
                </div>
              </div>
            )),
          )}
        </div>
      </div>
    </>
  );
};
