import { useAuth } from '@/hooks/auth.hook';
import moment from 'moment';
import { FC } from 'react';

interface IMembershipDateLeft {
  name: string;
  total_date: number;
  extension_at: string;
}

export const MembershipDateLeft: FC<IMembershipDateLeft> = props => {
  const { total_date, extension_at, name } = props;

  const { profile, mutateProfile } = useAuth();

  const extensionDate = moment(extension_at);
  const currentDate = moment();

  const differenceInDays = currentDate.diff(extensionDate, 'days');

  return (
    <div className='flex flex-col gap-2 rounded-lg bg-white px-5 py-4'>
      <div
        className='flex flex-row gap-2'
        style={{
          fontSize: '12px',
          fontWeight: 700,
        }}
      >
        <span style={{ color: '#1D3F75' }}>Current Membership</span>

        {/* <span className='text-red-600'>
          {profile?.membership?.cancel_at ? (
            `Cancel at ${moment(profile?.membership?.cancel_at, 'HH:mm MM/DD/YYYY').format('HH:mm MM/DD/YYYY')}`
          ) : (
            <></>
          )}
        </span> */}
      </div>
      <div className='grid grid-cols-2 gap-2'>
        <div className='font-hneu text-[12px] text-zelene-slight-blue'>Plan</div>
        <div className='font-hneu text-[12px] text-zelene-slight-blue'>Expiration Date</div>

        <div className='flex-1 font-hneu text-[12px] font-medium text-zelene-blue'>{name}</div>

        <div className='flex-1 font-hneu text-[12px] font-medium text-zelene-blue'>
          {total_date - differenceInDays} Days Left
        </div>
      </div>
    </div>
  );
};
