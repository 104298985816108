import axiosClient from '@/helper/call-center';
import { IMembershipCategory } from './interface';

export const membershipCategoryApi = {
  all() {
    return axiosClient.get('/membership-category');
  },
  paginate(params?: Record<string, any>) {
    return axiosClient.get<{ categories: IMembershipCategory[]; count: number }>('/membership-category/paginate', {
      params,
    });
  },
  allByLocation(location: string) {
    return axiosClient.get(`/membership/by-location/${location}`);
  },
  by1Location(location: string) {
    return axiosClient.get(`/membership-category/apply-to-location/${location}`);
  },
  create(data: any) {
    return axiosClient.post('/membership-category', data);
  },
  updateImage(id: string, image: File) {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return axiosClient.patch(`/membership-category/by-id/${id}/change-image`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  update(id: string, formData: FormData) {
    return axiosClient.put(`/membership-category/by-id/${id}/update-policy`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  delete(id: string) {
    return axiosClient.delete(`/membership-category/by-id/${id}`);
  },
};
