import { IProfile } from '@/interface/auth';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Divider, Modal, Tabs } from 'antd';
import Image from 'next/image';
import styled from 'styled-components';
import PercentPng from '../../../../public/images/Percent.png';
import Percent from '../../../../public/svgs/Percent.svg';

export interface IProfilePointProps {
  isModalOpen: boolean;
  profile: IProfile;
  setOpenModal: (v: boolean) => void;
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-nav-wrap {
    padding-top: 12px !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
    display: flex !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    transition: all 0.3s ease-in-out;
    &::before {
      border: none !important; /* Remove bottom border */
    }
  }

  .ant-tabs-content-holder {
    padding-top: 12px !important;
  }

  .ant-tabs-nav-list {
    // background-color: #f0f2f5;
    border-radius: 6px;
    gap: 4px;
  }

  .ant-tabs-tab-btn {
    width: 100%;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    padding: 6px 16px;
    border-radius: 6px;
    color: #637381;
    font-weight: 500;
    transition: all 0.3s;
    flex: 1;
    justify-content: center;
    text-align: center;

    &:hover {
      color: #1d3f75;
    }

    &.ant-tabs-tab-active {
      background-color: #1d3f75;

      .ant-tabs-tab-btn {
        color: white !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }
`;

const ProfilePoint = (props: IProfilePointProps) => {
  const { isModalOpen, profile, setOpenModal } = props;

  return (
    <Modal
      title={
        <div>
          <p className='mb-0 text-xl font-bold text-zelene-blue'>My Point</p>
        </div>
      }
      open={isModalOpen}
      onClose={() => setOpenModal(false)}
      onCancel={() => setOpenModal(false)}
      footer={[]}
      width={1000}
    >
      <StyledTabs
        defaultActiveKey='1'
        items={[
          {
            key: '1',
            label: 'Current Point',
            children: (
              <div className='rounded-md bg-[#DBEAF8] p-4'>
                <div className='pb-4 font-hneu text-sm font-medium text-zelene-blue'>Your Point</div>
                <div className='rounded-md bg-white px-5 py-4 font-hneu text-base font-medium text-zelene-blue'>
                {profile?.total_points}
                </div>
                {/* Display this section later
                 <Divider className='!my-4' />
                <div className='pb-4 font-hneu text-sm font-medium text-zelene-blue'>Suggested gift exchange</div>

                <div className='rounded-lg bg-white px-5 py-4'>
                  <div className='pb-4 font-hneu text-sm font-medium text-zelene-blue'>Voucher</div>

                  <div className='grid grid-cols-2 gap-4 *:col-span-1'>
                    {['1', '2', '3', '4'].map(item => (
                      <DiscountCard
                        date='120'
                        description='Discounts for services such as packages'
                        image={Percent}
                        name='Discount $20'
                        point='1.000'
                        key={item}
                      />
                    ))}
                  </div>

                  <Divider className='!my-3' />
                  <div className='pb-4 font-hneu text-sm font-medium text-zelene-blue'>Product</div>

                  <div className='grid grid-cols-2 gap-4 *:col-span-1'>
                    {['1', '2', '3', '4'].map(item => (
                      <DiscountCard
                        description='Discounts for services such as packages'
                        image={Percent}
                        name='Discount $20'
                        point='1.000'
                        key={item}
                      />
                    ))}
                  </div>
                </div> */}
              </div>
            ),
          },
          //Display this section later
          // {
          //   key: '2',
          //   label: 'History',
          //   children: (
          //     <div className='rounded-md bg-[#DBEAF8] p-4'>
          //       <div className='pb-4 font-hneu text-sm font-medium text-zelene-blue'>Your Point</div>
          //       <div className='rounded-md bg-[#FFFFFF] px-5 py-4'>
          //         {['1', '2', '3', '4'].map(item => (
          //           <div
          //             key={item}
          //             className='mb-4 flex items-center justify-center gap-4 rounded-lg bg-[#F6F6F6] px-4 py-2 last:mb-0'
          //           >
          //             <div className='flex flex-1 items-center gap-3'>
          //               <div className='text-sm'>10:12</div>
          //               <Image
          //                 src={PercentPng}
          //                 alt='percent'
          //                 width={42}
          //                 height={42}
          //                 className='!size-[42px] object-contain'
          //               />
          //               <div className='font-hneu text-[12px] font-medium text-zelene-blue'>
          //                 You have changed the “Voucher Name”
          //               </div>
          //             </div>

          //             <span className='font-hneu text-zelene-red'>-1.0000 P</span>
          //           </div>
          //         ))}
          //       </div>
          //     </div>
          //   ),
          // },
        ]}
      />
    </Modal>
  );
};

export default ProfilePoint;

interface IDiscountCardProps {
  name: string;
  description: string;
  date?: string;
  image: string;
  point: string;
}

const DiscountCard = (props: IDiscountCardProps) => {
  const { name, description, date, image, point } = props;

  return (
    <div className='flex items-center gap-4 rounded-2xl bg-[#FFF4E4] p-3'>
      <div className='flex size-[100px] items-center justify-center rounded-lg bg-[#FFFDF9] p-4'>
        <Image src={image} alt='img' width={48} height={48} className='!size-[48px] rounded-lg object-cover' />
      </div>

      <div className='flex flex-1 flex-col rounded-lg'>
        <span className='text-lg font-medium text-zelene-dark-blue'>{name}</span>
        <span className='text-sm font-medium text-zelene-grey'>{description}</span>
        {date && (
          <div className='flex h-full items-start gap-2 pt-3'>
            <ClockCircleOutlined />
            <span className='font-hneu text-[12px] text-zelene-grey'>{date}&nbsp;days</span>
          </div>
        )}
      </div>

      <div className='rounded-[44px] bg-[#D29A2A] px-4 py-2 text-white'>{point}P</div>
    </div>
  );
};
