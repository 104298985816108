import { StoreBranchApi } from '@/api-client/store-branch';
import { LOCAL_LOCATION } from '@/constants';
import { Select } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { CustomerMembershipDetail } from './membership-detail';

export const MembershipPersonal = () => {
  const [storeBranches, setStoreBranches] = useState<any[]>([]);
  const [storeBranchesChoose, setStoreBranchesChoose] = useState<string | null>(null);

  const fetchStoreBranches = useCallback(async () => {
    const data = await StoreBranchApi.getAll();

    setStoreBranches(data);
    setStoreBranchesChoose(LOCAL_LOCATION);
  }, []);

  useEffect(() => {
    fetchStoreBranches();
  }, []);

  return (
    <div className='flex max-h-[90%] flex-col gap-2 rounded-lg bg-[#DBEAF8] p-4'>
      <div className='hidden flex-col gap-2 md:flex'>
        <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Location</div>
        <Select
          size='large'
          showSearch
          className='w-full'
          placeholder=''
          optionFilterProp='label'
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={storeBranchesChoose}
          options={storeBranches.map((v: any) => {
            return {
              label: v.name,
              value: v._id,
            };
          })}
          onChange={value => {
            setStoreBranchesChoose(value);
          }}
        />
      </div>

      <CustomerMembershipDetail
        storeBranchesChoose={storeBranchesChoose}
        storeBranches={storeBranches}
        setStoreBranchesChoose={setStoreBranchesChoose}
        location={storeBranchesChoose}
      />
    </div>
  );
};
