import axiosClient from '@/helper/call-center';
import { ICustomerTakeTokenProps } from '@/interface/customer';

export const CustomerAPI = {
  // takeToken(params: ICustomerTakeTokenProps) {
  //   return axiosClient.post('/customer/take-token', params);
  // },
  getAllCustomer() {
    return axiosClient.get(`/customer/all`);
  },
  getDetailCustomer(id: string) {
    return axiosClient.get(`/customer/by-id/${id}`);
  },
  takeTokenNotLocation(params: ICustomerTakeTokenProps) {
    // @Cookies('cart_key') cart_key: string,
    return axiosClient.post(`/customer/take-token`, params);
  },
  takeTokenByLocation(params: ICustomerTakeTokenProps, locationId: string, cart_key: string) {
    // @Cookies('cart_key') cart_key: string,
    return axiosClient.post(`/customer/take-token/${locationId}`, params, { headers: { cart_key } });
  },
  updateprofile(params: IUpdateProfile) {
    return axiosClient.put(`/customer/update-profile`, { ...params });
  },
  updateBirthDate(birthDate: string) {
    return axiosClient.put(`/customer/update-birthday`, { birthdate: birthDate });
  },
  updateBirthDateByEmployee(birthDate: string, customerId: string) {
    return axiosClient.put(`/customer/by-id/${customerId}/update-birthday`, { birthdate: birthDate });
  },
  checkToken(token: string) {
    return axiosClient.get(`/customer/check-token`, {
      params: { token },
    });
  },
};

interface IUpdateProfile {
  zip?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password?: string;
  gender?: string;
}
