export interface ILocation {
  key: string;
  title: string;
  address: string;
  linkMap: string;
  phone: string;
  email: string;
  website: string;
  hours: string[];
  image: any;
  isOpening?: boolean;
  titleMenu: string;
  indexMenu: number;
}

export const locationsMockPage: ILocation[] = [
  {
    key: 'fortworth',
    title: 'Fort Worth',
    address: '9821 N Fwy #100, Fort Worth, TX 76177, United States',
    // linkMap: 'https://maps.app.goo.gl/xjnmwaZF1gUyu3497',
    linkMap: 'https://maps.app.goo.gl/tLzf1yodvqAgqYYh7?g_st=com.google.maps.preview.copy',
    phone: '(817) 650-4696',
    email: 'fortworth@zelenespa.com',
    website: 'zelenespa.com',
    hours: ['Monday to Saturday: 9 AM to 7 PM', 'Sunday: 10 AM to 6 PM'],
    image: '/images/Location/location-opening1.png',
    isOpening: true,
    titleMenu: 'Fort Worth',
    indexMenu: 4,
  },
  {
    key: 'dallaswest',
    title: 'Dallas',
    address: '2935 Blackburn St #110, Dallas, TX 75204, United States',
    // linkMap: 'https://maps.app.goo.gl/xjnmwaZF1gUyu3497',
    linkMap: 'https://maps.app.goo.gl/VkBYBrevuHZdBCYv5?g_st=com.google.maps.preview.copy',
    phone: '(817) 650-4745',
    email: 'dallas@zelenespa.com',
    website: 'zelenespa.com',
    hours: ['Monday to Friday: 10 AM to 8 PM', 'Saturday: 9 AM to 7 PM', 'Sunday: 10 AM to 6 PM'],
    image: '/images/Location/location-opening2.png',
    isOpening: true,
    titleMenu: 'Dallas',
    indexMenu: 5,
  },
  {
    key: 'mansfield',
    title: 'Mansfield',
    address: '348 Matlock Rd,Ste 104, Mansfield, TX 76063',
    linkMap:
      'https://www.google.com/maps/place/Hera+Head+Spa+Mansfield/@32.569764,-97.1002137,17z/data=!3m1!4b1!4m6!3m5!1s0x864e616f1372e595:0x18b9276a7bb21ac2!8m2!3d32.569764!4d-97.0976388!16s%2Fg%2F11stvkj223?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D',
    phone: '(469) 693-3887',
    email: 'mansfield@zelenespa.com',
    website: 'zelenespa.com',
    hours: ['Monday to Saturday: 9 AM to 7 PM', 'Sunday: 10 AM to 6 PM'],
    image: '/images/Location/mansfield.webp',
    titleMenu: 'Mansfield',
    indexMenu: 1,
  },
  {
    key: 'flower-mound',
    title: 'Flower Mound',
    address: '1400 Long Prairie Rd, Ste 130, Flower Mound, TX 75028',
    linkMap:
      'https://www.google.com/maps/place/Hera+Head+Spa+Flower+Mound/@33.0054123,-97.0728579,17z/data=!4m15!1m8!3m7!1s0x864c2ce775ea1a0b:0x53ae975b44bd0616!2s1400+Long+Prairie+Rd+ste+130,+Flower+Mound,+TX+75028!3b1!8m2!3d33.0054123!4d-97.070283!16s%2Fg%2F11w3h_z3ws!3m5!1s0x864c2d004f3a393d:0x210b8aa69540e5b5!8m2!3d33.0054123!4d-97.070283!16s%2Fg%2F11ldbb687k?entry=ttu&g_ep=EgoyMDI0MTExOS4yIKXMDSoASAFQAw%3D%3D',
    phone: '(469) 316-0298',
    email: 'flowermound@zelenespa.com',
    website: 'zelenespa.com',
    hours: ['Monday to Saturday: 9 AM to 7 PM', 'Sunday: 10 AM to 6 PM'],
    image: '/images/Location/flowermound.webp',
    titleMenu: 'Flower Mound',
    indexMenu: 2,
  },
  {
    key: 'frisco',
    title: 'Frisco',
    address: '8990 Coit Rd, Ste 300, Frisco, TX 75035',
    linkMap: 'https://www.google.com/maps?q=8990+Coit+Rd,+Ste+300,+Frisco,+TX+75035',
    phone: '(469) 207-1863',
    email: 'frisco@zelenespa.com',
    website: 'zelenespa.com',
    hours: ['Monday to Saturday: 9 AM to 7 PM', 'Sunday: 10 AM to 6 PM'],
    image: '/images/Location/frisco.webp',
    titleMenu: 'Frisco',
    indexMenu: 3,
  },
];
// get list location menu
export const locationsMenuMock = [...locationsMockPage].sort((a, b) => a.indexMenu - b.indexMenu);
