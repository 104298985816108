import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DatePicker, Modal, notification } from 'antd';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

interface IBirthDayControl {
  isOpen: boolean;
  handleClose: () => void;
  submit: (birthday: string) => void;
  content: string;
}

export const BirthDayControl: FC<IBirthDayControl> = props => {
  const { isOpen, handleClose, submit, content } = props;

  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());

  // const { mutateProfile } = useAuth();

  const handleUpdateBirthDate = async () => {
    if (!date) return;

    try {
      setLoading(true);
      await submit(date.format('MM-DD-YYYY'));

      setDate(dayjs());
      notification.success({
        message: 'Update Profile',
        description: 'Update birth date successfully',
      });
    } catch (err: any) {
      notification.error({
        message: 'Error',
        description: `${err?.response?.data?.message}`,
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      title={
        <div className='flex w-full justify-start'>
          <ExclamationCircleOutlined style={{ fontSize: 20, color: '#FABC3F' }} />
          <p className='mb-0 ml-2 text-lg font-bold'>Update Your Birth Day</p>
        </div>
      }
      open={isOpen}
      onOk={handleUpdateBirthDate}
      onCancel={handleClose}
      okText='Accept'
      cancelText='Cancel'
      closeIcon={null}
      centered
      loading={loading}
    >
      <div className='flex flex-col gap-2'>
        <p className='mb-0 text-sm font-normal'>{content}</p>
        <DatePicker value={date} onChange={v => setDate(v)} />
      </div>
    </Modal>
  );
};
