import { CloverApi } from '@/api-client/clover';
import { CustomerAPI } from '@/api-client/customer';
import { MembershipApi } from '@/api-client/membership';
import { MembershipDetailProvider, useMembershipDetailContext } from '@/components/contexts/membership-detail.context';
import ConfirmModal from '@/components/custom/modal/ConfirmModal';
import MemberShipCard from '@/components/sections/membership-card';
import { useAuth } from '@/hooks/auth.hook';
import { IMembership } from '@/interface/auth-v2';
import { IMembershipCategories } from '@/interface/membership';
import { EnumTypeAgreementMembership } from '@/utils/enum';
import { calculateDaysBetween, capitalizeFirstLetter, imgUrlTransferV1 } from '@/utils/global-func';
import { notification } from 'antd';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { BirthDayControl } from './birth-date-control';
import { CardControlBox } from './card-controll';
import { MembershipDateLeft } from './date-left';
import CreateMembershipFormCustomer from './membership-form';
import { ClientPolicyShowcase } from './policy-showcase';

interface IMembershipDetailItem {
  item: IMembershipCategories;
  membershipCard?: IMembership;
  location_target: string | null;

  storeBranchesChoose: string | null;
  storeBranches: any[];
  setStoreBranchesChoose: (value: string | null) => void;
}
const MembershipDetailItemChild: FC<IMembershipDetailItem> = props => {
  const { item, membershipCard, location_target, setStoreBranchesChoose, storeBranches, storeBranchesChoose } = props;

  const { mutateProfile, profile, firstLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<any>(null);
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [confirmChangeAutoRenewal, setConfirmChangeAutoRenewal] = useState(false);
  const [openBirthDayControl, setOpenBirthDayControl] = useState(false);
  const [openConsentForm, setOpenConsentForm] = useState(false);

  const currentMembershipCard = profile?.memberships?.find(m => {
    const { policy } = m;

    if (!location_target) {
      return false;
    }

    return policy?.category?.branchesApply?.map(b => b._id).includes(location_target);
  });

  const [differencePrice, setDifferencePrice] = useState(0);

  useEffect(() => {
    if (currentMembershipCard && item && currentMembershipCard?.policy?.category?._id !== item._id) {
      MembershipApi.calculateDifference({
        membershipId: currentMembershipCard?._id,
        targetCategoryId: item._id,
      }).then(res => {
        const { data } = res;
        setDifferencePrice(data);
      });
    }
  }, [currentMembershipCard, item]);

  const fetcher = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await CloverApi.getCloverInfoMembershipCategory(item._id);
      setInfo(data);
    } catch (error: any) {
      if (Array.isArray(error?.response?.data?.message)) {
        error?.response?.data?.message.forEach((item: any) => {
          notification.error({ message: 'Error', description: item });
        });
      } else {
        notification.error({
          message: 'Error',
          description: error?.response ? error.response.data?.message : error.message,
        });
      }
    } finally {
      setLoading(false);
    }
  }, [item._id]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  const { address, dateTime, formData, name, loadingForm, setLoadingForm, clearForm } = useMembershipDetailContext();

  const handleMembershipRegister = useCallback(async () => {
    if (loading || !location_target || loadingForm) {
      return;
    }

    setLoading(true);
    setLoadingForm(true);

    try {
      const formDataAdd = new FormData();
      if (address && name && formData) {
        formDataAdd.append('address', address);
        formDataAdd.append('name', name);
        formDataAdd.append('file', formData, formData.name);
        formDataAdd.append('dateTime', dateTime.format('YYYY-MM-DD'));
        formDataAdd.append('keyForm', EnumTypeAgreementMembership.FORM_1);
      }
      formDataAdd.append('location_target', location_target);
      formDataAdd.append('categoryId', item._id);
      await MembershipApi.customerRegister(formDataAdd);

      notification.success({
        message: 'Membership',
        description: `Your ${item?.sort_name} membership has been registered successfully!`,
      });
      setOpenConsentForm(false);
      clearForm();

      await fetcher();
      await mutateProfile();
    } catch (err: any) {
      notification.error({ message: 'Membership', description: `${err?.response?.data?.message}` });
    } finally {
      setLoading(false);
      setLoadingForm(false);
    }
  }, [item, location_target, loading, address, name, formData, dateTime, loadingForm]);

  const handleChangeAutoRenewMembership = useCallback(async () => {
    if (!currentMembershipCard) {
      return;
    }

    try {
      await MembershipApi.updateAutoRenewalCard(
        currentMembershipCard?._id,
        !currentMembershipCard.is_recurring_enabled,
      );

      notification.success({
        message: 'Membership Cancellation',
        description: `Your membership ${currentMembershipCard?.policy?.category?.sort_name} has been ${currentMembershipCard.is_recurring_enabled ? 'inactive' : 'active'} renewal successfully!`,
      });

      await mutateProfile();
    } catch (err: any) {
      notification.error({ message: 'Cancel Membership Failed.', description: `${err?.response?.data?.message}` });
    }
  }, [currentMembershipCard?._id, currentMembershipCard?.cancel_at, currentMembershipCard?.is_recurring_enabled]);

  const allowChangeAutoRenewal = currentMembershipCard
    ? calculateDaysBetween(new Date(), new Date(currentMembershipCard?.created_at)) > 90
    : false;

  const card = info?.cards?.elements?.length > 0 ? info?.cards?.elements[0] : null;
  const reference = info?.reference as string | undefined;

  const { policy: _policy } = item;

  const isValidUpGrade = currentMembershipCard?.policy?.price
    ? Number(currentMembershipCard?.policy?.price || 0) <= Number(item?.policy?.price || 0)
    : false;

  return (
    <div className='mt-3 flex flex-col gap-3'>
      <div className='font-hneu text-[12px] font-medium text-zelene-blue'>Membership Details</div>

      <MemberShipCard
        image={item?.thumbnail ? imgUrlTransferV1(item?.thumbnail) : '/images/placeholder.jpg'}
        name={item?.sort_name || ''}
        description={item?.description || ''}
        price={Number(item?.policy?.price)}
        date={`${item?.policy.days_count}`}
        showCheckbox={false}
        currentMembershipCard={currentMembershipCard}
      />
      <CardControlBox card={card} reference={reference} reload={() => fetcher()} membershipCategoryId={item._id} />
      {membershipCard ? (
        <div className='flex flex-col gap-2 font-hneu'>
          <MembershipDateLeft
            name={membershipCard?.policy?.category?.sort_name}
            total_date={membershipCard?.policy?.days_count}
            extension_at={membershipCard.extension_at}
          />
          {/* {!loading ? (
            <div className='flex flex-col gap-2'>
              <div className='flex flex-row items-center gap-2'>
                <Checkbox
                  checked={membershipCard.is_recurring_enabled}
                  onChange={async () => {
                    if (loading) {
                      return;
                    }

                    setLoading(true);

                    try {
                      await MembershipApi.updateAutoRenewalCard(
                        membershipCard._id,
                        !membershipCard.is_recurring_enabled,
                      );
                      await mutateProfile();
                      notification.success({
                        message: 'Update Auto Renewal Success',
                        description: `Your membership ${item?.sort_name} has been updated successfully!`,
                      });
                    } catch (err: any) {
                      notification.error({
                        message: 'Update Auto Renewal Failed.',
                        description: `${err?.response?.data?.message}`,
                      });
                    } finally {
                      setLoading(false);
                    }
                  }}
                />
                <span className='font-hneu text-[12px] font-medium text-zelene-blue'>Auto Renewal</span>
              </div>

              <div className='flex flex-row items-stretch gap-2'>
                <div className='flex items-center justify-center text-2xl'>*</div>
                <div className='flex translate-y-1 items-center justify-center'>
                  <p className='font-hneu text-xs font-medium text-zelene-blue'>
                    Automatically renew the membership package
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <span>process...</span>
          )} */}
        </div>
      ) : (
        <></>
      )}
      {card ? (
        <div
          className='flex flex-row items-center justify-center'
          style={{
            height: '38px',
            padding: '12px 48px',
            borderRadius: '44px',
            transition: 'all ease .3s',
            cursor: currentMembershipCard?._id && !isValidUpGrade ? 'not-allowed' : 'pointer',
            backgroundColor:
              currentMembershipCard?._id && currentMembershipCard?.policy?.category?._id === item._id
                ? currentMembershipCard.is_recurring_enabled
                  ? '#B84E44'
                  : '#D29A2A'
                : currentMembershipCard?._id && !isValidUpGrade
                  ? '#a7a7a7'
                  : '#D29A2A',
          }}
          onClick={() => {
            if (loading) return;

            if (!currentMembershipCard?._id) {
              if (profile.date_of_birth) {
                setOpenConsentForm(true);
                // handleMembershipRegister();
              } else {
                setOpenBirthDayControl(true);
              }
            }

            if (currentMembershipCard?.policy?.category?._id === item._id) {
              if (!allowChangeAutoRenewal && currentMembershipCard.is_recurring_enabled) {
                setConfirmChangeAutoRenewal(true);
              } else {
                handleChangeAutoRenewMembership();
              }
            } else {
              if (isValidUpGrade) {
                setConfirmUpgrade(true);
              }
            }
          }}
        >
          <span
            className='font-hneu font-semibold capitalize'
            style={{
              color: '#FFF',
              fontSize: '14px',
            }}
          >
            {loading || firstLoading
              ? 'Please wait...'
              : currentMembershipCard?._id && currentMembershipCard?.policy?.category?._id === item._id
                ? currentMembershipCard.is_recurring_enabled
                  ? `Cancel Renewal`
                  : `Enable Auto Renewal`
                : currentMembershipCard?._id && !isValidUpGrade
                  ? 'downgrade not available'
                  : currentMembershipCard?._id
                    ? 'Upgrade Your Membership'
                    : `Join Membership Now`}
          </span>
        </div>
      ) : (
        <></>
      )}

      <BirthDayControl
        isOpen={openBirthDayControl}
        handleClose={() => setOpenBirthDayControl(false)}
        submit={async birthday => {
          await CustomerAPI.updateBirthDate(birthday);
          await mutateProfile();
          setOpenConsentForm(true);
          // handleMembershipRegister();
        }}
        content="Don't miss out on special birthday surprises just for you! 🎉 Add your date of birth now to unlock exclusive rewards and gifts on your special day!"
      />

      <ConfirmModal
        isLoading={loading}
        title='Membership Cancellation'
        handleCancel={() => setConfirmChangeAutoRenewal(false)}
        handleConfirm={async () => {
          setConfirmChangeAutoRenewal(false);
        }}
        open={confirmChangeAutoRenewal}
        // `This membership card has been inactive for 90 days. Auto-renewal cannot be canceled until the card has been active for at least 90 days.
        content={`Your ${capitalizeFirstLetter(currentMembershipCard?.policy?.category?.sort_name || '')} membership must be active for at least 90 days before you can cancel. 
        Please proceed with the cancellation after ${moment(currentMembershipCard?.created_at).add(90, 'days').format('MMMM DD, YYYY')}.`}
        hiddenOk={true}
      />

      <ConfirmModal
        isLoading={loading}
        title='Confirm upgrade membership'
        handleCancel={() => setConfirmUpgrade(false)}
        handleConfirm={async () => {
          if (currentMembershipCard) {
            await handleMembershipRegister();
          }

          setConfirmUpgrade(false);
        }}
        open={confirmUpgrade}
        content={`Upgrading membership will require paying the difference of $${differencePrice}.`}
      />

      <CreateMembershipFormCustomer
        membershipCategory={currentMembershipCard}
        onClose={() => {
          setOpenConsentForm(false);
        }}
        open={openConsentForm}
        handleSubmit={handleMembershipRegister}
      />

      <ClientPolicyShowcase policy={_policy} />
    </div>
  );
};

export default function MembershipDetailItem(props: IMembershipDetailItem) {
  return (
    <MembershipDetailProvider>
      <MembershipDetailItemChild {...props} />
    </MembershipDetailProvider>
  );
}
