import { create } from 'zustand';

interface MembershipStore {
  membershipOpen: boolean;
  toggleMembership: () => void;
  setMembershipOpen: (open: boolean) => void;
  close: () => void;
}

export const useMembershipAction = create<MembershipStore>(set => ({
  membershipOpen: false, // Mặc định đóng
  toggleMembership: () => set(state => ({ membershipOpen: !state.membershipOpen })),
  setMembershipOpen: open => set({ membershipOpen: true }),
  close: () => set({ membershipOpen: false }),
}));
