import { IMembershipCategories } from '@/interface/membership';

export interface IContentMembershipFormProps {
  category: IMembershipCategories | null;
}

export default function ContentMembershipForm(props: IContentMembershipFormProps) {
  const { category } = props;
  return (
    <div>
      <div className='border-b-[1px] border-gray-300 pb-3'>
        <div className='flex flex-col gap-2'>
          <p className='mb-0 text-base font-bold'>BACKGROUND</p>
          <p className='mb-0 pl-3 text-base font-normal'>
            <span className='font-bold'>A.</span> The Client wishes to engage the Business to provide services, and the
            Business wishes to provide services to the Client.
          </p>
          <p className='mb-0 pl-3 text-base font-normal'>
            <span className='font-bold'>B.</span> The Business is agreeable to providing such services to the Member on
            the terms and conditions set out in this Agreement.
          </p>
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <p className='mb-0 text-base font-normal'>
          <span className='font-bold'>IN CONSIDERATION OF</span> the matters described above and of the mutual benefits
          and obligations set forth in this Agreement, the receipt and sufficiency of which consideration is hereby
          acknowledged, the Member and the Business (individually the "Party" and collectively the "Parties" to this
          Agreement) agree as follows:
        </p>

        <div>
          <div className='flex flex-col gap-2'>
            <p className='mb-0 text-base font-bold'>SERVICES PROVIDED</p>
            <p className='mb-0 pl-3 text-base font-normal'>
              <span className='font-bold'>1.</span> The Member hereby agrees to engage the Business to provide the
              Member with the following services (the "Services"):
            </p>
            <p className='mb-0 pl-6 text-base font-normal'>
              <span className='pr-2 font-bold'>• </span> Membership Program: Providing hair and scalp spa offering
              natural, effective, and luxurious hair and scalp treatments, as well as facials, and brow and lash
              treatments.
            </p>
            <p className='mb-0 pl-3 text-base font-normal'>
              <span className='font-bold'>2.</span> The Business hereby agrees to provide such Services to the Member.
            </p>
          </div>
        </div>
        <p className='mb-0 text-base font-bold'>CURRENCY</p>
        <p className='mb-0 pl-3 text-base font-bold'>
          A. EXCEPT AS OTHERWISE PROVIDED IN THIS AGREEMENT, ALL MONETARY AMOUNTS REFERRED TO IN THIS AGREEMENT ARE IN
          USD (US DOLLARS).
        </p>
        <p className='mb-0 text-base font-bold'>TERM OF AGREEMENT</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>3.</span> The term of this Agreement (the "Term") will begin on the date of this
          Agreement and will remain in full force and effect indefinitely until terminated as provided in this
          Agreement.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>4.</span> This Agreement may be terminated at any time by the Business.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>5.</span> This Agreement may be terminated by the Member{' '}
          <span className='font-bold'>after</span> the first [90 days] free of charge.{' '}
          <span className='font-bold'>
            Termination before the first [90 days] will result in a [$150] early cancellation fee.
          </span>
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>6.</span> Except as otherwise provided in this Agreement, the obligations of the
          Business will end upon the termination of this Agreement. It is required that termination of this Agreement be
          conveyed by written or emailed notice.
        </p>
        <p className='mb-0 text-base font-bold'>TERM OF AGREEMENT</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>7.</span> The Business will charge the Member for the Monthly Fees at the rate
          specified in the membership agreement ( the “Compensation”), which may vary based on the selected membership
          tier. The Member hereby consents to electronic automatic payment of the monthly fees.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>8.</span> Service-related fees after membership discount still apply for every
          appointment booked.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>9.</span> Invoices submitted by the Business to the Member are due upon receipt.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>10.</span> The Compensation as stated in this Agreement does not include sales
          tax, or other applicable duties as may be required by law. Any sales tax and duties required by law will be
          charged to the Member in addition to the Compensation.
        </p>
        <p className='mb-0 pl-3 text-base font-bold'>
          11. A 20% gratuity will apply to each membership appointment to ensure fair compensation for all of our
          providers. This will be due at check-out.
        </p>
        <p className='mb-0 text-base font-bold'>CONFIDENTIALITY</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>12.</span> Confidential information (the "Confidential Information") refers to any
          data or information relating to the business of the Member which would reasonably be considered to be
          proprietary to the Member including, but not limited to, accounting records, business processes, and client
          records and that is not generally known in the industry of the Member and where the release of that
          Confidential Information could reasonably be expected to cause harm to the Client.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>13.</span> The Business agrees that they will not disclose, divulge, reveal,
          report or use, for any purpose, any Confidential Information which the Business has obtained, except as
          authorized by the Member or as required by law. The obligations of confidentiality will apply during the Term
          and will survive indefinitely upon termination of this Agreement.
        </p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>14.</span> All written and oral information and material disclosed or provided by
          the Member to the Business under this Agreement is Confidential Information regardless of whether it was
          provided before or after the date of this Agreement or how it was provided to the Contractor.
        </p>
        <p className='mb-0 text-base font-bold'>AUTONOMY</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>15.</span> Except as otherwise provided in this Agreement, the Business will have
          full control over working time, methods, and decision making in relation to provision of the Services in
          accordance with the Agreement. The Business will work autonomously and not at the direction of the Member.
        </p>
        <p className='mb-0 text-base font-bold'>EQUIPMENT</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>16.</span> Except as otherwise provided in this Agreement, the Business will
          provide at the Business’s own expense, any and all tools, machinery, equipment, raw materials, supplies,
          workwear and any other items or parts necessary to deliver the Services in accordance with the Agreement.
        </p>
        <p className='mb-0 text-base font-bold'>NO EXCLUSIVITY</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>17.</span> The Parties acknowledge that this Agreement is non-exclusive and that
          either Party will be free, during and after the Term, to engage or contract with third parties for the
          provision of services similar to the Services.
        </p>
        <p className='mb-0 text-base font-bold'>NOTICE</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>18.</span> All notices, requests, demands or other communications required or
          permitted by the terms of this Agreement will be given in writing and delivered to the Parties at the
          following addresses:
        </p>
      </div>
      {category?.branchesApply &&
        category?.branchesApply.length > 0 &&
        category.branchesApply?.map((branch, index) => (
          <div key={index}>
            <p className='mb-0 pl-6 text-base font-bold'>
              <span className='pr-2 font-bold'>• </span> {branch?.name || '...'}
            </p>
            <p className='mb-0 pl-11 text-base font-normal'>{branch?.address || ''}</p>
          </div>
        ))}
      <div className='flex flex-col gap-4'>
        <p className='mb-0 pl-3 text-base font-normal'>
          or to such other address as either Party may from time to time notify the other, and will be deemed to be
          properly delivered (a) immediately upon being served personally, (b) two days after being deposited with the
          postal service if served by registered mail, or (c) the following day after being deposited with an overnight
          courier.
        </p>
        <p className='mb-0 text-base font-bold'>INDEMNIFICATION</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>19.</span> Except to the extent paid in settlement from any applicable insurance
          policies, and to the extent permitted by applicable law, each Party agrees to indemnify and hold harmless the
          other Party, and its respective directors, shareholders, affiliates, officers, agents, employees, and
          permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive
          damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from or
          arise out of any act or omission of the indemnifying party, its respective directors, shareholders,
          affiliates, officers, agents, employees, and permitted successors and assigns that occurs in connection with
          this Agreement. This indemnification will survive the termination of this Agreement.
        </p>
        <p className='mb-0 text-base font-bold'>TITLES/HEADINGS</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>20.</span> Headings are inserted for the convenience of the Parties only and are
          not to be considered when interpreting this Agreement.
        </p>
        <p className='mb-0 text-base font-bold'>GOVERNING LAW</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>21.</span> This Agreement will be governed by and construed in accordance with the
          laws of the State of Texas.
        </p>
        <p className='mb-0 text-base font-bold'>SEVERABILITY</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>22.</span> In the event that any of the provisions of this Agreement are held to
          be invalid or unenforceable in whole or in part, all other provisions will nevertheless continue to be valid
          and enforceable with the invalid or unenforceable parts severed from the remainder of this Agreement.
        </p>
        <p className='mb-0 text-base font-bold'>WAIVER</p>
        <p className='mb-0 pl-3 text-base font-normal'>
          <span className='font-bold'>23.</span> The waiver by either Party of a breach, default, delay or omission of
          any of the provisions of this Agreement by the other Party will not be construed as a waiver of any subsequent
          breach of the same or other provisions.
        </p>
      </div>
    </div>
  );
}
